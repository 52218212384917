import cn from "classnames"
import React from "react"
import css from './CourseList.module.css';
import { Link } from "@reach/router"

export const CoursesList = ({ opened }) => {
  return (
    <div className={cn('container-big', css.wrapper, {[css.closed]: !opened})}>
      <div className="row">
        <div className="col-xs-12 col-md-4">
          <div className="text-paragraph-subtitle bold">CORSI ANGULAR</div>
          <ul style={{paddingRight: 30}}>
            <li><Link to="corso-angular-core-concepts"> ANGULAR CORE CONCEPTS</Link></li>
            <li>MASTERING ANGULAR</li>
            <li>MASTERING COMPONENTS</li>
            <li>MASTERING REACTIVE FORMS</li>
            <li>ANGULAR, REDUX & NGRX</li>

          </ul>
        </div>

        <div className="col-xs-12 col-md-4">
          <div className="text-paragraph-subtitle bold">VIDEO CORSI</div>
          <ul style={{paddingRight: 30}}>
            <li>ANGULAR FUNDAMENTALS</li>
            <li><Link to="corso-angular-ngrx"> ANGULAR, REDUX & NGRX</Link></li>
          </ul>
        </div>

        <div className="col-xs-12 col-md-4">
          <div className="text-paragraph-subtitle bold">CORSI REACT</div>
          <ul style={{paddingRight: 30}}>
            <li>REACT CORE CONCEPTS</li>
            <li>REACT / REDUX</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
