import { TweenMax, ScrollToPlugin, Power2 } from "gsap/all";
const plugins = [ ScrollToPlugin ];

export const goto = (url, target, scrollToID) => {
  switch (target) {

    case '_self':
      window.location = url;
      break;

    case '_email':
      // window.open(url);
      break;

    case '_scrollTo':
      // window.open(url);
      TweenMax.to(window, 2, { scrollTo: scrollToID, ease: Power2.easeOut})
      break;

    default:
    case '_blank':
      window.open(url);
      break;
  }
}
