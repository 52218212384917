// import separator from "../../../images/separator-2@1x.svg"
import React from "react"

export const SeparatorWave = ({ bg1 = '#dedede', bg2 = '#efefef'}) => (
  <svg width="100%"  viewBox="0 0 1540 182">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="1">
      <g id="Group-4">
        <path d="M0.371073552,45.3716522 C93.1989895,129.117402 568.944854,129.117402 643.487508,118.685581 C802.70146,96.4044936 1263.06765,-11.4577741 1539.38069,38.0559367 L1540,107.439475 C1188.25578,63.0453908 972.212339,112.627644 769.690345,135.972918 C454.564718,172.298326 228.110035,225.22681 0.371073552,115.772527 L0.371073552,45.3716522 Z" id="Rectangle" fill={bg1}></path>
        <path d="M0.371073552,11.7082202 C252.739764,111.115453 318.0278,124.716808 602.460987,103.197602 C929.268675,78.4724914 1265.36562,-52.3184695 1539.38069,25 L1539.38069,89.5937188 C1364.85264,41.7082202 1003.24942,71.5113426 911.80001,90.066252 C649.522212,143.281912 284.61013,193.837462 0.371073552,73.5392745 L0.371073552,11.7082202 Z" id="Rectangle" fill={bg2}></path>
      </g>
    </g>
  </svg>
)
