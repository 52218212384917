import React from 'react';
import css from './Footer.module.css';
import cn from 'classnames';
import { faCaretRight, faLink } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


export const Footer = () => {
  return (
    <div className={cn('container-fluid', css.wrapper)}>

      <div className={css.content}>
        <div className="row">
          <div className={cn('col-xs-12 col-sm-6', css.col)}>
            <div className={css.title}>
              <span className="light">FABIO</span><span className="bold">BIONDI</span>
            </div>
            <div>Trieste (Italy)</div>
            <div>P.I. 0111623017</div>
            <div className={css.separator}></div>
            <div><FontAwesomeIcon icon={faLink} /> <a target="_blank" href="http://www.fabiobiondi.io">fabiobiondi.io</a></div>
            <div><FontAwesomeIcon icon={faLink} /> <a target="_blank" href="http://training.fabiobiondi.io">Tech Blog</a></div>
          </div>
          <div className={cn('col-xs-12 col-sm-6', css.col)}>
            <div className={css.title}>Follow me on...</div>
            <div><FontAwesomeIcon icon={faCaretRight} /> <a href="https://www.facebook.com/biondifabio/" target="_blank">Facebook</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} /> <a href="https://www.youtube.com/user/BiondiFabio" target="_blank">YouTube Channel</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} /> <a href="https://www.linkedin.com/in/fabiobiondi/" target="_blank">LinkedInProfile</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} /> <a href="https://www.instagram.com/biondifabio/" target="_blank">Instagram</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} /> <a href="https://twitter.com/biondifabio" target="_blank">Twitter</a></div>
          </div>
        </div>
      </div>
    </div>
  )
};

