import { Link } from "gatsby"
import PropTypes from "prop-types"
import React,{ useState } from "react"
import { CoursesList } from "./CourseList"

import { faBars, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { goto } from "../../utils/links"

const CoursesMenuCollapsable = ({ siteTitle }) => {
  const [opened, setOpened] = useState(false);
  return (
    <header >
      <div className="container-big" >
        <div className="row" style={{ padding: '20px 0 0px 0', }}>
          <div className="col-xs-6" >
            <Link to="/" style={{ textDecoration: `none`, fontSize: 30, color: 'black' }}>
              {/*{siteTitle}*/}
              <div className="text-box-title">
                <span className="light">F</span><span className="light hide-on-mobile">ABIO</span><span className="bold">BIONDI</span>
              </div>
            </Link>
          </div>

          <div
            className="col-xs-6 right pointer"
            style={{marginTop: 15}}
          >
            <span onClick={() => setOpened(!opened)}>
              <FontAwesomeIcon icon={faBars} fixedWidth={20} /> Tutti i corsi
            </span>

            <span style={{margin: '0 10px'}}> | </span>
            <span onClick={() => goto('/forms/richiedi-preventivo-corso', '_self')}>
              <FontAwesomeIcon icon={faEnvelope} style={{fontSize: 20}} />
              <span className="hide-on-mobile"> Contattami</span>
            </span>

          </div>
        </div>
      </div>

      <CoursesList opened={opened}></CoursesList>


    </header>
)}

CoursesMenuCollapsable.propTypes = {
  siteTitle: PropTypes.string,
}

CoursesMenuCollapsable.defaultProps = {
  siteTitle: ``,
}

export default CoursesMenuCollapsable
